import { createStore, combineReducers } from "redux";

import reducers from "./reducers";

const appReducer = combineReducers({
  reducers,
});

const store = createStore(appReducer);

// Exports
export default store;
