import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useTracking = (trackingId) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (!trackingId) {
        console.error(
          "Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`."
        );
        return;
      }
      window.gtag("config", trackingId, { page_path: location.pathname });
    });

    return unlisten;
  }, [trackingId, listen]);
};

export const setTrackingPatient = async (patient) => {
  if (patient && patient.CustomerId) {
    let properties = {
      customerId: patient.CustomerId,
    };
    if (patient.Email) {
      properties.email = patient.Email;
    }
    if (patient.Phone) {
      properties.phone = patient.Phone;
    }
    if (patient.Patient_Name) {
      properties.name = patient.Patient_Name;
    }
    window.freshpaint.identify(patient.CustomerId, properties);
  }
};
