import React, { createContext, useState } from "react";

export const Context = createContext();

const Provider = ({ children }) => {
  const [utmSource, setUtmSource] = useState(() =>
    window.sessionStorage.UtmSourceMoons
      ? window.sessionStorage.UtmSourceMoons
      : ""
  );
  const [utmCampaign, setUtmCampaign] = useState(() =>
    window.sessionStorage.utmCampaign ? window.sessionStorage.utmCampaign : ""
  );
  const [variant, setVariant] = useState(() =>
    window.sessionStorage.VariantMoons
      ? window.sessionStorage.VariantMoons
      : "0"
  );
  const [quiz, setQuiz] = useState(() =>
    window.sessionStorage.quiz ? JSON.parse(window.sessionStorage.quiz) : {}
  );

  const value = {
    utmSource,
    updateUtmSource: (value) => {
      setUtmSource(value);
      window.sessionStorage.UtmSourceMoons = value;
    },
    utmCampaign,
    updateUtmCampaign: (value) => {
      setUtmCampaign(value);
      window.sessionStorage.utmCampaign = value;
    },
    variant,
    updateVariant: (newVariant) => {
      setVariant(newVariant);
      window.sessionStorage.VariantMoons = newVariant;
    },
    quiz,
    updateQuiz: (key, value) => {
      quiz[key] = value;
      setQuiz(quiz);
      window.sessionStorage.quiz = JSON.stringify(quiz);
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
// eslint-disable-next-line
export default { Provider, Consumer: Context.Consumer };
