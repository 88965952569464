// Initial State

const initialState = {
  params: "",
  variant: "MX_Original_Quiz",
  name: "",
  phone: "",
  email: "",
  response: {},
};

// Reducers (Modifies The State And Returns A New State)
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UTM_SOURCE":
      return { ...state, params: action.params };
    case "SET_VARIANT":
      return { ...state, variant: action.variant };
    case "SET_NAME":
      return { ...state, name: action.name };
    case "SET_PHONE":
      return { ...state, phone: action.phone };
    case "SET_EMAIL":
      return { ...state, email: action.email };
    case "SET_RESPONSE":
      return { ...state, response: action.response };
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default reducers;
