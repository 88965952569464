import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import Context from "./Context";
import { getUTMs } from "./util/UTM";
import ScrollToTop from "./util/ScrollToTop";
import { useTracking } from "./util/tracking";
const NoCandidate = lazy(() => import("./pages/no-candidate/NoCandidate"));
const Home = lazy(() => import("./pages/home"));

export const Views = () => {
  useTracking(process.env.REACT_APP_GA_ID);
  const [loadWindow, setLoadWindow] = React.useState(false);

  React.useEffect(() => {
    window.onload = function () {
      setTimeout(() => {
        setLoadWindow(true);
      },3000);
    };
  },[loadWindow]);

  const HelmetJs = () => {
    if (loadWindow) {
      return (
        <Helmet>
          {/* Google Tag Manager */}
          <script async type="text/javascript" src="js/gtag.js" />
          {/* Freshpaint */}
          <script async type="text/javascript" src="js/freshpaint.js" />
         
         
          {/* Google Tag Manager - Google Analytics  */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-139614898-4" />
          {/* Google Optimize  */}
          <script async src="https://www.googleoptimize.com/optimize.js?id=OPT-N7KV84X" />
        </Helmet>
      );
    }
    return <div></div>;
  }

  return (
    <ScrollToTop>
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/start-quiz" component={Home} />
          <Route exact path="/nocandidatokit" render={() => <NoCandidate />} />
          <Route path="/" render={() => <Redirect to="/" />} />
        </Switch>
        <HelmetJs />
      </Suspense>
    </ScrollToTop>
  );
};

function App() {
  getUTMs();
  return (
    <BrowserRouter>
      <Context.Provider>
        <Views />
      </Context.Provider>
    </BrowserRouter>
  );
}

export default App;
